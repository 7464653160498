<template>
  <div class="wrap">
    <Navbar :title="$t('games.title')" :leftArrow="false" />
    <keep-alive>
      <div class="nav_tabs">
        <van-swipe class="my-swipe" width="118" :loop="false" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in gameType" :key="index" @click="navChange(index)">
            <div class="tab_title" :class="navNow == index ? 'active' : ''">
              <div class="icons">
                <van-icon size="35" :color="navNow == index ? 'var(--light)' : '#5c5f60'" name="bookmark-o"
                  v-if="index == 0" />
                <img class="img" :src="item.icon" alt="" v-else>
              </div>
              <div class="title">
                {{ item.title }}
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </keep-alive>
    <div class="merchants_wrap">
      <div class="merchants_item" v-for="(item, index) in gameArr" :key="index" @click="toGame(item)">
        <div class="content_wrap">
          <img :src="item.picture" alt="" class="left" />
          <div class="title">{{ item.title }}</div>
          <div class="subtitle">{{ item.subtitle }}</div>
        </div>
      </div>
    </div>
    <!-- <Tabbar active="make-money" /> -->
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import { $post } from "@/utils/request";
import { cookie } from "../../utils";
import Navbar from "@/components/JNav";
import { Tab, Tabs, Icon, Swipe, SwipeItem } from 'vant'
export default {
  components: {
    Tabbar,
    Navbar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data() {
    this.navs = [
      { title: this.$t("game.all"), value: "all" },
      { title: this.$t("merchant.hot"), value: "hot" },
      { title: this.$t("merchant.list"), value: "all" },
    ];
    return {
      navNow: 0,
      // merchants: [],
      interval: null,
      getDataInterval: null,
    };
  },
  created() {
    // this.getMerchants();

  },
  computed: {
    gameList() {
      const type = this.navs[this.navNow].value;
      switch (type) {
        case "all":
          return this.$store.getters.AllGameList;
        case "hot":
          return this.$store.getters.HotGameList;
        default:
          break;
      }
    },
    gameType() {
      const arr = [{ code: 'all', title: this.$t("game.all") }]
      return [...arr, ...this.$store.getters.GameType]
    },
    merchants() {
      return this.$store.getters.AllGameList
    },
    gameArr() {
      const type = this.gameType[this.navNow].code;
      if (type == 'all') {
        return this.merchants || [];
      }
      return this.merchants.filter(item => item.category == type) || [];
    }
  },
  watch: {
    // gameList(val) {
    //   if (val.length) {
    //     this.getMerchants();
    //   }
    // },
  },
  methods: {
    navChange(index) {
      this.navNow = index;
    },
    async getMerchants() {
      try {
        const type = this.navs[this.navNow].value;
        const data = this.gameList;
        if (!!this.interval) {
          clearInterval(this.interval);
        }
        let time = 0;
        this.merchants = this.dataInit(data);
        this.interval = setInterval(() => {
          time++;
          this.merchants = this.dataInit(data, time);
        }, 1000);
      } catch (error) { }
    },
    dataInit(data, time = 0) {
      const arr = [...data];
      for (let i = 0, len = arr.length; i < len; i++) {
        const item = data[i];
        try {
          if (item.currentinfo.countdown - time >= 0) {
            item.countTime = this.countFormat(
              item.currentinfo.countdown - time,
              item
            );
          }
        } catch (error) {
          item.countTime = this.$t("status.open");
        }
      }
      return arr;
    },
    countFormat(num, data) {
      if (num <= 0) {
        const { opentime, endtime } = data.currentinfo;
        this.getDataInterval = setTimeout(() => {
          // this.getMerchants();
          this.$store.dispatch("getGameList", { type: this.navs[this.navNow].value })
        }, (opentime - endtime) * 1000);
        return this.$t("status.open");
      }
      const hourUnit = 60 * 60;
      const minuteUnit = 60;
      const secondUnit = 1;
      const targetH = Math.floor(num / hourUnit);
      const targetM = Math.floor((num % hourUnit) / minuteUnit);
      const targetS = Math.floor(((num % hourUnit) % minuteUnit) / secondUnit);
      return `${this.format(targetH)}:${this.format(targetM)}:${this.format(
        targetS
      )}`;
    },
    format(num) {
      if (num >= 10) return num;
      else return "0" + num;
    },
    // 进入玩法
    toGame(item) {
      const token = cookie.get("token");
      if (!!token) this.$router.push(`/Game?type=${item.type}&category=${item.category}`);
      else this.$router.push("/login");
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 4px;
@comBG: linear-gradient(#093958, #022c47);

.wrap {
  @wrap_gap: @wrapPadding;
  min-height: 100vh;

  /deep/ .van-nav-bar {
    background-color: transparent;

    .van-nav-bar__content {
      background-color: transparent;

      .van-nav-bar__title {
        color: var(--light);
      }
    }
  }

  .nav_tabs {
    margin: calc(20rem / 16) calc(10rem / 16);
    overflow: hidden;

    /deep/ .van-swipe__track {
      .van-swipe-item {
        height: calc(83rem / 16);

        .tab_title {
          width: 108px;
          padding: 0;
          padding-top: calc(7rem / 16);
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          background-color: #132235;
          border-radius: calc(10rem / 16);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;

          .icons {
            .img {
              width: calc(35rem / 16);
              height: calc(35rem / 16);
            }
          }

          .title {
            color: #5c5f60;
            font-size: calc(13rem / 16);
            text-align: center;
          }
        }

        .active {
          background-image: linear-gradient(179deg, #13a2ba, #087c95);

          .title {
            font-size: calc(14rem / 16);
            color: var(--light);
          }
        }
      }

      .van-swipe-item:nth-last-child(1) {
        .tab_title {
          width: 118px;
        }
      }
    }
  }

  .merchants_wrap {
    padding: calc(13rem / 16);
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: calc(5rem / 16);

    .merchants_item {
      width: calc((100% - (10rem / 16)) / 3);
      border-radius: 10px;
      background-color: #132235;

      .content_wrap {
        padding: calc(20rem / 16) 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        .left {
          width: calc(50rem / 16);
          height: calc(50rem / 16);
          display: block;
        }

        .title {
          font-size: calc(15rem / 16);
          color: var(--light);
          font-weight: 700;
          text-align: center;
          padding: calc(10rem / 16);
          min-height: calc(40rem / 16);
        }

        .subtitle {
          color: #989898;
          font-size: calc(13rem / 16);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 calc(10rem / 16);
        }
      }
    }
  }
}
</style>
